import React from 'react';

function Login() {

  return (
    <main>

        <img src='cool.png'></img>

    </main>
  );
}

export default Login;
