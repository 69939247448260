import React, { useState, useEffect, useCallback, useRef} from 'react';
import { Link } from "react-router-dom";
import Confetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};

function Main() {
  const [message, setMessage]= useState('');

  const fetchMessage = async () => {
    const response = await fetch('/api/message');
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMessage();
      setMessage(data.content);
    };

    fetchData();
  }, []);

  const YMDFormatter= (num) => {
    if (!num) return "";

    var formatNum = '';

    num = num.replace(/\s/gi, "");

    try {
        if (num.length === 8) {

            formatNum = num.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }
    } catch (e) {

        formatNum = num;

        console.log(e);

    }
    return formatNum;
  }

  const excelClick = () => {
    window.location.href = "https://docs.google.com/spreadsheets/d/1ZJCm840tEU--xYkSstZVJtWe0_UVRHV3H7KcdFLXwww/edit?usp=sharing";
  }

  const weddingClick = () => {
    window.location.href = "https://mypage.eltower.com/login_m.jsp";
  }

  const refAnimationInstance = useRef(null);
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);
  const makeShot = useCallback(() => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        particleCount: 100,
        startVelocity: 30,
        spread: 100,
        origin: {
          x: Math.random(),
          y: Math.random() - 0.2
        }
      });
  }, []);

  const fire = useCallback(() => {
    makeShot();
  }, [makeShot]);

  const startingDate = new Date(YMDFormatter('20220624'));
  const weddingDate = new Date(YMDFormatter('20241027'));
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const startingDDay = Math.ceil((currentDate.getTime() - startingDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

  const weddingDDay = Math.ceil((weddingDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)) - 1;
  let weddingDDayString = '' + weddingDDay;
  if(weddingDDay > 0){
    weddingDDayString = 'D-' + weddingDDayString;
  } else if(weddingDDay === 0){
    weddingDDayString = 'D-DAY';
  } else{
    weddingDDayString = 'D+' + ((weddingDDay*(-1)) + 1);
  }

  return (
    <main>

      <p id="menu">Menu</p>

      <p id="recent-message">{message}</p>

      <div className="button">
        <Link to='/blog'>
          <button type="button">
            <span>블로그</span>
          </button>
        </Link>
      </div>

      <div className="button">
        <Link to='/calendar'>
          <button type="button">
            <span>찌니 달력</span>
          </button>
        </Link>
      </div>

      <div className="button">
        <button type="button" onClick={excelClick}>
          <span>결혼 플랜</span>
        </button>
      </div>

      <div className="button">
        <button type="button" onClick={fire}>
          D+{startingDDay}
        </button>
      </div>
      <Confetti refConfetti={getInstance} style={canvasStyles} />

      <div className="button">
        <button type="button" onClick={weddingClick}>
          {weddingDDayString}
        </button>
      </div>

      <div className="button">
        <Link to='/cool'>
          <button type="button">
            <span>멋진 사진</span>
          </button>
        </Link>
      </div>

    </main>

  );
}

export default Main;
