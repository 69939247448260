import React from 'react';

function NotFound() {

  return (
    <main>
      <p id="menu">404 Error</p>
    </main>

  );
}

export default NotFound;
