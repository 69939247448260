import React from 'react';

function Video2() {

  return (
    <main>

      <p id="menu">Video 2</p>

      <video width="100%" height="auto" controls>
        <source src="video2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </main>
  );
}

export default Video2;
