import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from './Modal'

const isClicked = [false, false, false, false, false, false, false, false, false, false, false, false];

function Calendar() {
  const [oneYearAgo, setOneYearAgo] = useState([]);
  const [januarys, setJanuarys] = useState([]);
  const [februarys, setFebruarys] = useState([]);
  const [marchs, setMarchs] = useState([]);
  const [aprils, setAprils] = useState([]);
  const [mays, setMays] = useState([]);
  const [junes, setJunes] = useState([]);
  const [julys, setJulys] = useState([]);
  const [agusts, setAgusts] = useState([]);
  const [septembers, setSeptembers] = useState([]);
  const [octobers, setOctobers] = useState([]);
  const [novembers, setNovembers] = useState([]);
  const [decembers, setDecembers] = useState([]);
  const [getMoment, setMoment]=useState(moment());
  const [modalOpen, setModalOpen] = useState(false);
  const [oneYearAgoOpen, setOneYearAgoOpen] = useState(false);
  const today = getMoment;

  const showModal = () => {
    setModalOpen(true);
  };

  const clearCalendars = () => {
    setJanuarys([]);
    setFebruarys([]);
    setMarchs([]);
    setAprils([]);
    setMays([]);
    setJunes([]);
    setJulys([]);
    setAgusts([]);
    setSeptembers([]);
    setOctobers([]);
    setNovembers([]);
    setDecembers([]);
  };

  const fetchOneYearAgo = async () => {
    const response = await fetch('/api/yearago');
    const data = await response.json();
    return data;
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchOneYearAgo();
      setOneYearAgo(data);
    };

    fetchData();
  }, []);

  const fetchCalendars = async (month) => {
    if(isClicked[month]){
      isClicked[month]=false;
      switch(month){
        case 0:
          setJanuarys([]);
          break;
        case 1:
          setFebruarys([]);
          break;
        case 2:
          setMarchs([]);
          break;
        case 3:
          setAprils([]);
          break;
        case 4:
          setMays([]);
          break;
        case 5:
          setJunes([]);
          break;
        case 6:
          setJulys([]);
          break;
        case 7:
          setAgusts([]);
          break;
        case 8:
          setSeptembers([]);
          break;
        case 9:
          setOctobers([]);
          break;
        case 10:
          setNovembers([]);
          break;
        case 11:
          setDecembers([]);
          break;
        default:
          break;
      }
    }
    else{
      isClicked[month]=true;
      const response = await fetch('/api/calendar?year=' + today.format('YYYY') + '&month=' + month);
      const data = await response.json();
      switch(month){
        case 0:
          setJanuarys(data);
          break;
        case 1:
          setFebruarys(data);
          break;
        case 2:
          setMarchs(data);
          break;
        case 3:
          setAprils(data);
          break;
        case 4:
          setMays(data);
          break;
        case 5:
          setJunes(data);
          break;
        case 6:
          setJulys(data);
          break;
        case 7:
          setAgusts(data);
          break;
        case 8:
          setSeptembers(data);
          break;
        case 9:
          setOctobers(data);
          break;
        case 10:
          setNovembers(data);
          break;
        case 11:
          setDecembers(data);
          break;
        default:
          break;
      }
    }
  };


  return (

    <main>
      <div className="oneYearAgo">
        <ul>
          <li className="textOneYearAgo" onClick={()=>{ setOneYearAgoOpen(!oneYearAgoOpen)}}>
            1년 전 오늘
          </li>
        </ul>
      </div>
      { oneYearAgoOpen &&
      oneYearAgo.map((oneYear) => (
        <ul className="calendar" style={{display:"block"}}>
          <li className='imgList' style={{display:"inline-block"}}>
            <a>
              <img src={oneYear.src.slice(21)} alt='alt'/>
              <span className='caldate'>{oneYear.datetime}</span>
              <span className='title'>{oneYear.title}</span>
            </a>
          </li>
        </ul>
      ))
      }
      <div className="year">
        <ul>
          <li className="nextYear" onClick={()=>{ setMoment(getMoment.clone().subtract(1, 'year')); clearCalendars(); }} >&lt;</li>
          <li className="textYear">
            {today.format('YYYY')}
          </li>
          <li className="nextYear" onClick={()=>{ setMoment(getMoment.clone().add(1, 'year')); clearCalendars(); }}>&gt;</li>
        </ul>
      </div>

      <ul className="calendar">
        <li className="month" onClick={()=>{ fetchCalendars(0) }}>
          <a>1</a>
        </li>
        {januarys.map((january) => (
          <li className='imgList'>
            <a>
              <img src={january.src.slice(21)} alt='alt'/>
              <span className='caldate'>{january.caldate}</span>
              <span className='title'>{january.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(1) }}>
          <a>2</a>
        </li>
        {februarys.map((february) => (
          <li className='imgList'>
            <a>
              <img src={february.src.slice(21)} alt='alt'/>
              <span className='caldate'>{february.caldate}</span>
              <span className='title'>{february.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(2) }}>
          <a>3</a>
        </li>
        {marchs.map((march) => (
          <li className='imgList'>
            <a>
              <img src={march.src.slice(21)} alt='alt'/>
              <span className='caldate'>{march.caldate}</span>
              <span className='title'>{march.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(3) }}>
          <a>4</a>
        </li>
        {aprils.map((april) => (
          <li className='imgList'>
            <a>
              <img src={april.src.slice(21)} alt='alt'/>
              <span className='caldate'>{april.caldate}</span>
              <span className='title'>{april.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(4) }}>
          <a>5</a>
        </li>
        {mays.map((may) => (
          <li className='imgList'>
            <a>
              <img src={may.src.slice(21)} alt='alt'/>
              <span className='caldate'>{may.caldate}</span>
              <span className='title'>{may.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(5) }}>
          <a>6</a>
        </li>
        {junes.map((june) => (
          <li className='imgList'>
            <a>
              <img src={june.src.slice(21)} alt='alt'/>
              <span className='caldate'>{june.caldate}</span>
              <span className='title'>{june.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(6) }}>
          <a>7</a>
        </li>
        {julys.map((july) => (
          <li className='imgList'>
            <a>
              <img src={july.src.slice(21)} alt='alt'/>
              <span className='caldate'>{july.caldate}</span>
              <span className='title'>{july.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(7) }}>
          <a>8</a>
        </li>
        {agusts.map((agust) => (
          <li className='imgList'>
            <a>
              <img src={agust.src.slice(21)} alt='alt'/>
              <span className='caldate'>{agust.caldate}</span>
              <span className='title'>{agust.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(8) }}>
          <a>9</a>
        </li>
        {septembers.map((september) => (
          <li className='imgList'>
            <a>
              <img src={september.src.slice(21)} alt='alt'/>
              <span className='caldate'>{september.caldate}</span>
              <span className='title'>{september.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(9) }}>
          <a>10</a>
        </li>
        {octobers.map((october) => (
          <li className='imgList'>
            <a>
              <img src={october.src.slice(21)} alt='alt'/>
              <span className='caldate'>{october.caldate}</span>
              <span className='title'>{october.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(10) }}>
          <a>11</a>
        </li>
        {novembers.map((november) => (
          <li className='imgList'>
            <a>
              <img src={november.src.slice(21)} alt='alt'/>
              <span className='caldate'>{november.caldate}</span>
              <span className='title'>{november.title}</span>
            </a>
          </li>
        ))}
        <li className="month" onClick={()=>{ fetchCalendars(11) }}>
          <a>12</a>
        </li>
        {decembers.map((december) => (
          <li className='imgList'>
            <a>
              <img src={december.src.slice(21)} alt='alt'/>
              <span className='caldate'>{december.caldate}</span>
              <span className='title'>{december.title}</span>
            </a>
          </li>
        ))}
      </ul>

      <div className="postCalendar" onClick={ showModal }>
        <ul>
          <li className="upload">+</li>
        </ul>
      </div>

      <br />
      <br />

      <audio controls>
        <source src="200.m4a" type='audio/mpeg;codecs="mp3"' />
      </audio>

      { modalOpen && <Modal setModalOpen={ setModalOpen } /> }

    </main>

  );
}

export default Calendar;
