import React, { useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './component/Header';
import Footer from './component/Footer';
import Blog from './component/Blog';
import Main from './component/Main';
import Calendar from './component/Calendar';
import Video1 from './component/Video1';
import Video2 from './component/Video2';
import Login from './component/Login';
import NotFound from './component/NotFound';
import Cool from './component/Cool';
import './App.css';

function App() {
  const [authenticated, setAuthenticated]= useState(false);

  const fetchAuthenticated = async () => {
    const response = await fetch('/api/authenticate');
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAuthenticated();
      console.log(data[0].authenticated);
      setAuthenticated(data[0].authenticated);
    };

    fetchData();
  }, []);


  if(authenticated){
    return (
      <div>
        <BrowserRouter>
          <Header/>
          <Routes>
            <Route path='/' element={<Main/>}></Route>
            <Route path='/blog' element={<Blog/>}></Route>
            <Route path='/calendar' element={<Calendar/>}></Route>
            <Route path='/video1' element={<Video1/>}></Route>
            <Route path='/video2' element={<Video2/>}></Route>
            <Route path='/login' element={<Main/>}></Route>
            <Route path='/cool' element={<Cool/>}></Route>
            <Route path='*' element={<NotFound/>}></Route>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </div>
    );

  }
  else{
    return (
      <div>
        <BrowserRouter>
          <Header/>
          <Routes>
            <Route path='*' element={<Login/>}></Route>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
