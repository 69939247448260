import React from 'react';

function Login() {

  return (
    <main>

      <p id="menu">Login</p>

      <div className="login-form">
        <form method="POST" action="/api/login">
          <input type="text" name="id" className="text-field" placeholder="id" />
          <input type="password" name="password" className="text-field" placeholder="password" />
          <button type="submit">Login</button>
        </form>
      </div>

    </main>
  );
}

export default Login;
