import React from 'react';

function Footer() {
  return (
    <footer>
    <div id="footer-line">
      <hr className="line" />
    </div>
    <p>Copyright 2023 zziny.com All rights reserved.</p>
  </footer>
  );
}

export default Footer;
