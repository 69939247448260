import React, { useState } from 'react';
import axios from 'axios';

function Modal({setModalOpen}) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [image, setImage] = useState(null);
  const [text, setText] = useState('');

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('datetime', selectedDate);
    formData.append('title', text);
    formData.append('image', image);

    try {
      await axios.post('/api/calendar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('calendar inserted successfully');
      closeModal();
    } catch (error) {
      console.error('Failed to insert calendar:', error);
    }
  };

  const closeModal = () => {
      setModalOpen(false);
  };

  return (
    <div id="myModal" className="modal">
      <h2>Upload Calendar</h2>
      <hr className="line" />
      <p>upload images and content</p>
      <div>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>
      <div>
        <input type="date" name="datetime" onChange={handleDateChange}/>
      </div>
      <div>
      <input type="text" name="title" placeholder="Input text" onChange={handleTextChange}/>
      </div>
      <div>
        <button id="calendar_upload"  onClick={handleSubmit}>Save</button>
      </div>
      <button className="close" onClick={closeModal}>Close</button>
    </div>
  );
}
export default Modal;