import React from 'react';
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <Link to='/' style={{ textDecoration: "none" }}>
        <p id='title'>Zziny.com</p>
      </Link>
      <div id="header-line">
        <hr className="line" id="left-line" />
        <span id="center-line">♥</span>
        <hr className="line" id="right-line" />
      </div>
    </header>
  );
}

export default Header;
