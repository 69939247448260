import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function Blog() {
  const [posts, setPosts] = useState([]);
  const [content, setContent] = useState('');

  const fetchPosts = async () => {
    const response = await fetch('/api/blog');
    const data = await response.json();
    setPosts(data);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/blog', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content }),
    });
    const data = await response.json();
    console.log(data);

    setContent('');
    fetchPosts();
  };

  return (
    <main>

      <p id="menu">Blog</p>

      <div id="post-form">
        <form onSubmit={handleSubmit}>
          <input id="post-input"
            type="text"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Input message" />
          <button id="post-button" type="submit">Submit</button>
        </form>
      </div>

      <table id="blog-table">
          <thead>
              <tr>
                  <th>No</th>
                  <th>Messages</th>
                  <th>Date</th>
              </tr>
          </thead>
          <tbody>
            {posts.map((post) => (
              <tr>
                <td className="td-no">{post.post_id}</td>
                <td>{post.content}</td>
                <td className="td-date">{post.date}</td>
              </tr>
            ))}
          </tbody>
      </table>

      <div className="button">
        <Link to='/video1'>
          <button type="button">
            <span>Video 1</span>
          </button>
        </Link>
      </div>

      <div className="button">
        <Link to='/video2'>
          <button type="button">
            <span>Video 2</span>
          </button>
        </Link>
      </div>

    </main>
  );
}

export default Blog;
